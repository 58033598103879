.tarjetas__tarjetasDiv {
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
  width: 100%;
}

.tarjetas__iconotarjetasDiv {
  display: flex;
  align-items: center;
  width: 7rem;
  background-color: $primary;
  padding: 1rem;
}

.tarjetas__iconotarjetas {
  width: 100%;
  font-size: 50px;
  color: white;
}

.tarjetas__detalletarjetasDiv {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  padding: 1rem;
}


.tarjetas__cardDiv {
  width: 100%;
  height: 50px;
  padding: 0 0.1rem;

  img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width:800px) {
  .tarjetas__tarjetasDiv {
    min-height: 150px;
  }
}

@media screen and (min-width:1200px) {
  .tarjetas__tarjetasDiv {
    min-height: 200px;
  }

  .tarjetas__iconotarjetasDiv {
    width: 12rem;
    padding: 2rem;
  }
  
.tarjetas__iconotarjetas {
  font-size: 80px;
}

.tarjetas__detalletarjetasDiv {
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.tarjetas__cardDiv {
  width: 100%;
  height: 40px;
  margin: auto;

  img {
    width: 100%;
  }
}
}