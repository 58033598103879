.googleMaps__iframe {
  width: 100%;
}

@media screen and (min-width:1200px) {

  .googleMaps__iframe {
    height: 450px;
  }
}


