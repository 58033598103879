* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.slider {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
}

.slide {
    display: flex;
    align-items: center;
    animation: slideshow 20s linear infinite;
}

.slideImg {
    width: 8rem;
    height: 6rem;

    img {
        height: 100%;
        width: 100%;
        padding: 0 1rem;
        object-fit: contain;
    }

}

@keyframes slideshow {
    0% {transform: translateX(0);}
    100% {transform: translateX(-100%);}
}

@media screen and (min-width:1200px) {
    .slider {
        max-width: 1400px;
    }

    .slide {
        animation: slideshow 25s linear infinite;
    }

    .slideImg {
        width: 20rem;
        height: 10rem;

        img {
            padding: 0 3rem;
        }
    }
}