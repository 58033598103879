.contactScreen__banner {
  width: 100%;
  height: 100%;
}

.contactScreen__bannerDiv {
  position: relative;
  display: flex;

  &::before {
    pointer-events: none;
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #141f4b 0%, #1f253fa5 70%, rgba(84, 101, 163, 0.406) 100%);
  opacity: 0.6;
  top: 0;
  right: 0;
  position: absolute;
  }

  &::after {
    content: "Contacto";
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 1);
    background-clip: text;
    color: transparent;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-family: $merienda;
    font-weight: 600;
  }
}

.contactScreen__informacion {
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 1rem;
  margin: 1rem;
}

.contactScreen_GoogleMaps {
  margin-bottom: 1rem;
}

@media screen and (min-width:800px) {
  .contactScreen__informacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 2rem;
  }

  .contactScreen_GoogleMaps {
    margin-bottom: 2rem;
  }
}

@media screen and (min-width:1200px) {
  .contactScreen__informacionFlex {
    display: flex;
    justify-content: center;
  }

  .contactScreen__informacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: auto;
    margin: 5rem;
    max-width: 1400px;
    justify-items: center;
  }

  .contactScreen__GoogleMapsFlex {
    display: flex;
    justify-content: center;
  }

  .contactScreen__GoogleMaps {
    margin: 0 5rem 5rem 5rem;
    width: 100%;
    max-width: 1400px;
  }
}