.aboutUs__banner {
  width: 100%;
}

.aboutUs__bannerDiv {
  position: relative;
  display: flex;

  &::before {
    pointer-events: none;
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #141f4b 0%, #1f253fa5 70%, rgba(84, 101, 163, 0.406) 100%);
  opacity: 0.6;
  top: 0;
  right: 0;
  position: absolute;
  }

  &::after {
    content: "Nosotros";
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 1);
    background-clip: text;
    color: transparent;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-family: $merienda;
    font-weight: 600;
  }
}

.aboutUs__divDescripcion{
  margin: 1rem;
}

.aboutUs__divDescripcion p{
  padding: 1rem;
  font-family: $poppins;
  font-weight: 500;
}

.aboutUs__divDescripcion img {
  width: 100%;
}

.aboutUs__GaleriaImagenes {
  margin: 2rem;
}


@media screen and (min-width:1200px) {

  .aboutUs__GaleriaImagenesFlex {
    display: flex;
    justify-content: center;
  }
  
  .aboutUs__GaleriaImagenes {
    display: flex;
    align-content: center;
    flex-direction: column;
    margin: 2rem 5rem;
    max-width: 1400px;
  }

  .aboutUs__divDescripcionFlex {
    display: flex;
    justify-content: center;
  }

  .aboutUs__divDescripcion{
    margin: 2rem 5rem;
    max-width: 1400px;
  }
}
