.heroHome_section {
  position: relative;
  height: 300px;
  background-image: url("../../../public/assets/Fondos/fondoArena.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroHome_section::before {
  pointer-events: none;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(#1f263f, 0.8) 20%,
    rgba(#1f263f, 0.7) 80%,
    rgba(#1f263f, 0.8) 100%
  );
  top: 0;
  right: 0;
  position: absolute;
}

.heroHome_article {
  display: flex;
  flex-direction: column;
  gap: 5;
}

.heroHome_h1 {
  color: white;
  z-index: 1;
  font-family: $merienda;
  font-size: 40px;
  text-align: center;
}

.heroHome_h2 {
  color: #efefef;
  z-index: 1;
  font-family: $merienda;
  font-size: 20px;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .heroHome_section {
    height: 400px;
  }
}

@media screen and (min-width: 800px) {
  .heroHome_section {
    height: 500px;
  }
  .heroHome_h1 {
    font-size: 70px;
  }
  .heroHome_h2 {
    font-size: 35px;
  }
}

@media screen and (min-width: 1200px) {
  .heroHome_section {
    height: 550px;
  }
  .heroHome_h1 {
    font-size: 70px;
  }
  .heroHome_h2 {
    font-size: 35px;
  }
}
