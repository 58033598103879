.visitarBanner__bannerImg {
  width: 100%;
  height: 100%;
}

.visitarBanner__parentDiv {
  position: relative;
  display: flex;
  width: 100%;
  background-image: url('../../../public/assets/banners/visitarBanner.webp');
  background-size: cover;
  background-position-y: top;
  &::before {
    pointer-events: none;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(#1f263f, 0.8) 20%,
      rgba(#1f263f, 0.6) 80%,
      rgba(#1f263f, 0.8) 100%
    );
    top: 0;
    right: 0;
    position: absolute;
  }
}

.visitarBanner__consultaDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  z-index: 1;

  h5 {
    color: white;
    font-family: $poppins;
    font-size: 17px;
  }
}

.visitarBanner__consultaText {
  width: 60%;
}

.visitarBanner__consultaHashtag {
  display: flex;

  h6 {
    font-family: $poppins;
    font-size: 15px;
    font-weight: 600;
    color: #2eb2ff;
    padding-right: 7px;
  }
}

.visitarBanner__consultaLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #2eb2ff;
  color: white;
  margin: auto;
  text-decoration: none;
  border-radius: 3px;

  h6 {
    font-family: $poppins;
    font-size: 12px;
    font-weight: 600;
  }

  :hover {
    cursor: pointer;
  }
}

.visitarBanner__icono {
  font-size: 20px;
  padding-right: 5px;
}

@media screen and (min-width: 500px) {
  .visitarBanner__parentDiv {
    height: 150px;

    &::before {
      width: 100%;
      height: 100%;
    }
  }
  .visitarBanner__consultaDiv {
    padding: 1.5rem;
    h5 {
      font-size: 25px;
    }
  }

  .visitarBanner__consultaText {
    width: 60%;
  }

  .visitarBanner__consultaHashtag {
    h6 {
      font-size: 18px;
      padding-right: 10px;
    }
  }

  .visitarBanner__consultaLogo {
    padding: 0.7rem;
    h6 {
      font-size: 15px;
    }
  }

  .visitarBanner__icono {
    font-size: 25px;
    padding-right: 5px;
  }
}

@media screen and (min-width: 800px) {
  .visitarBanner__parentDiv {
    height: 200px;

    &::before {
      width: 100%;
      height: 100%;
    }
  }
  .visitarBanner__consultaDiv {
    padding: 3rem;
    h5 {
      color: white;
      font-family: $poppins;
      font-size: 35px;
    }
  }

  .visitarBanner__consultaText {
    width: 54%;
  }

  .visitarBanner__consultaHashtag {
    h6 {
      font-size: 20px;
      padding-right: 12px;
    }
  }

  .visitarBanner__consultaLogo {
    padding: 1rem;
    position: relative;
    z-index: 1;
    h6 {
      font-size: 20px;
    }
  }

  .visitarBanner__icono {
    font-size: 30px;
    padding-right: 5px;
  }
}

@media (hover: hover) and (min-width: 800px) {
  .visitarBanner__consultaLogo ::after {
    position: absolute;
    content: '';
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: white;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  .visitarBanner__consultaLogo:hover {
    cursor: pointer;
    color: $primary;
    transition: 0.3s;

    ::after {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .visitarBanner__parentDiv {
    height: 300px;
    display: flex;
    justify-content: center;

    &::before {
      width: 100%;
      height: 100%;
    }
  }
  .visitarBanner__consultaDiv {
    max-width: 1400px;
    padding: 5rem;
    h5 {
      color: white;
      font-family: $poppins;
      font-size: 48px;
    }
  }

  .visitarBanner__consultaHashtag {
    h6 {
      font-size: 25px;
      padding-right: 15px;
    }
  }

  .visitarBanner__consultaLogo {
    padding: 1.5rem;
    h6 {
      font-size: 25px;
    }
  }

  .visitarBanner__icono {
    font-size: 35px;
    padding-right: 8px;
  }

  .visitarBanner__consultaText {
    width: 60%;
  }
}
