.homescreen__title {
  display: flex;
  justify-content: center;
  font-family: $poppins;
  color: $primary;
  font-size: 1.3rem;
  font-weight: 600;
}

.homescreen_ProductsBanner {
  margin: 1rem 0;
}

.homescreen__AutoCarousel2Div {
  margin: 1rem 0;
}

.homescreen__informacion {
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 1rem;
  margin: 1rem;
}

.homescreen__GoogleMaps {
  margin-bottom: 1rem;
}

@media screen and (min-width:800px) {
  
  .homescreen__ProductsBannerDiv{
    margin: 2rem;
  }

  .homescreen__WhatsappBannerDiv {
    margin: 2rem;
  }

  .homescreen__informacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 2rem;
  }

  .homescreen__AutoCarousel2Div {
    margin: 2rem 0;
  }

  .homescreen_ProductsBanner {
    margin: 2rem 0;
  }

  .homescreen__GoogleMaps {
      margin: 2rem;
  }
}


@media screen and (min-width:1200px) {
  .homescreen__title {
    font-size: 2rem;
    padding: 2rem 0;
    font-weight: 600;
  }

  .homescreen__informacionFlex {
    display: flex;
    justify-content: center;
  }
  
  .homescreen__informacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: auto;
    margin: 5rem;
    max-width: 1400px;
    justify-items: center;
  }

  .homescreen_ProductsBanner {
    margin: 5rem 0;
  }

  .homescreen__AutoCarousel2Div {
    margin: 5rem 0;
  }

  .homescreen__GoogleMapsFlex {
    display: flex;
    justify-content: center;
  }

  .homescreen__GoogleMaps {
    margin: 0 5rem 5rem 5rem;
    width: 100%;
    max-width: 1400px;
  }
}