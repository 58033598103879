.productsList__parentDiv {
  display: flex;
  justify-content: center;
  padding: 0rem 1rem 1rem 1rem;
}

.productsList__gridDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  width: 100%;
}

.productList__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;

  button {
    padding: 0 0.3rem;
    color: $primary;
    font-size: 1.5rem;
    background-color: white;
    border: none;
  }
}

.productList__AutoCarousel2Div {
  margin: 1rem 0;
}

.productsList__loadingDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  padding-top: 10vh;
}

.MuiAlert-root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
}

.css-1pxa9xg-MuiAlert-message {
  text-overflow: ellipsis;
  overflow: hidden !important;
}

@media screen and (min-width: 680px) {
  .productsList__gridDiv {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
  }

  .productList__pagination {
    button {
      padding: 0 1rem;
      color: $primary;
      font-size: 2.3rem;
      background-color: white;
      border: none;
      cursor: pointer;
    }
  }

  .productList_AutoCarousel2 {
    margin: 2rem 0;
  }
}

@media screen and (min-width: 800px) {
  .productsList__gridDiv {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .productsList__parentDiv {
    padding: 0rem 5rem 1rem 5rem;
  }

  .productsList__gridDiv {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
    width: 100%;
    max-width: 1400px;
  }

  .productList__marginDiv {
    margin-bottom: 1rem;
  }

  .productList__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    button {
      padding: 0 1rem;
      color: $primary;
      font-size: 2.3rem;
      background-color: white;
      border: none;
      cursor: pointer;
    }
  }

  .MuiAlert-root {
    margin: 3rem 5rem;
  }

  .MuiAlert-message {
    font-size: 25px;
  }

  .MuiAlert-icon svg {
    font-size: 40px;
  }

  .productList_AutoCarousel2 {
    margin: 5rem 0;
  }
}
