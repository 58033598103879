.productCard__parentDiv {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  border-radius: 0.1rem;
  width: 100%;
  height: 16rem;
}

.productCard__imgDiv {
  width: 100%;
  height: 10rem;
  padding: 0.5rem;
}

.productCard__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productCard__details {
  width: 100%;
  height: 5rem;
  padding: 0.5rem 0.5rem;
  position: relative;
}

.productCard__details::before {
  content: '';
  position: absolute;
  top: -2px;
  left: 5%;
  height: 1px;
  width: 90%;
  border-top: 2px solid $primary;
  overflow: hidden;
}

.productCard__priceDiv {
  font-family: $poppins;
  font-size: 1rem;

  h3 {
    font-weight: 500;
  }
}

.productCard__titleDiv {
  display: -webkit-box;
  font-family: $poppins;
  font-size: 0.8rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;

  h4 {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
  }
}

.productCard__whatsapp {
  background-color: #25d366;
  font-weight: 500;
  border-radius: 3px;
  font-family: $poppins;
  color: white;
  padding: 6px 10px;
  text-decoration: none;
  list-style: none;
  transition: 0.3s;
  font-size: 12px;
}

.productCard__whatsapp:hover {
  background-color: #075e54;
}

.productCard__whatsappDiv {
  margin-top: 8px;
}

@media screen and (min-width: 1200px) {
  .productCard__parentDiv {
    width: 100%;
    height: 26rem;
  }

  .productCard__imgDiv {
    width: 100%;
    height: 15rem;
    padding: 1rem;
  }

  .productCard__details {
    padding: 1rem;
  }

  .productCard__priceDiv {
    font-family: $poppins;
    font-size: 1.3rem;

    h3 {
      font-weight: 500;
    }
  }

  .productCard__titleDiv {
    font-size: 1.2rem;
    -webkit-line-clamp: 3;

    h4 {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .productCard__whatsapp {
    background-color: #25d366;
    font-weight: 500;
    border-radius: 3px;
    font-family: $poppins;
    color: white;
    padding: 10px 16px;
    text-decoration: none;
    list-style: none;
    transition: 0.3s;
    font-size: 16px;
  }

  .productCard__whatsappDiv {
    margin-top: 20px;
  }
}
