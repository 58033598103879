.atencion__atencionDiv {
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
  width: 100%;
}

.atencion__iconoAtencionDiv {
  display: flex;
  align-items: center;
  width: 7rem;
  background-color: $primary;
  padding: 1rem;
}

.atencion__iconoAtencion {
  width: 100%;
  font-size: 50px;
  color: white;
}

.atencion__detalleAtencionDiv {
  font-family: $poppins;
  width: 100%;
  text-align: start;
  padding: 1rem;

  p{
    color: rgba(0, 0, 0, 0.8);
  }
}

.atencion__horaDetalleAtencionDiv {
  display: flex;
  justify-content: center;

  p {
    margin: 0 5px;
  }
}

.atencion__tarjetaDiv {
  display: flex;
}

@media screen and (min-width:800px) {
  .atencion__atencionDiv {
    min-height: 150px;
  }
}

@media screen and (min-width:1200px) {
  .atencion__atencionDiv {
    min-height: 200px;
  }

  .atencion__iconoAtencionDiv {
    width: 12rem;
    padding: 2rem;
  }

  .atencion__iconoAtencion {
    font-size: 80px;
  }

  .atencion__detalleAtencionDiv {
    padding: 1.5rem;

    h4 {
      font-size: 22px ;
    }
  
    p{
      font-size: 20px;
    }
  }
}