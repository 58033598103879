.searchFilter__div {
  margin: 1rem;
}

.searchFilter__form{
  width: 100%;
  background-color: $primary;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 50px;
}

.searchFilter__input {
  background-color: transparent;
  color: white;
  flex: 1;
  border: 0;
  outline: none;
  font-size: 16px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.765);
  }
}

.searchFilter__icon {
  font-size: 16px;
  color: white;
}

@media screen and (min-width:800px) {
  .searchFilter__div {
    margin: 1.5rem 2rem;
  }
}

@media screen and (min-width:1200px) {

  .searchFilter__div {
    margin: 3rem 5rem;
    display: flex;
    justify-content: center;
  }

  .searchFilter__form{
    width: 100%;
    max-width: 1400px;
    background-color: $primary;
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    border-radius: 50px;
  }

  .searchFilter__input {
    background-color: transparent;
    color: white;
    flex: 1;
    border: 0;
    outline: none;
    font-size: 20px;
  
    &::placeholder {
      color: rgba(255, 255, 255, 0.765);
    }
  }
  
  .searchFilter__icon {
    font-size: 20px;
    color: white;
  }
}