.cardcategories__parentDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.cardcategories__a {
  width: 100%;
}

.cardcategories__divOne {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.cardcategories__divOne img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  float: left;
}

.cardcategories__titleDiv {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba($primary, 1) 6%,
    rgba($primary, 0) 90%,
    rgba($primary, 0.7) 100%
  );
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 3.5vw 3vw;
  z-index: 1;

  ::before {
    content: '';
    width: 100%;
    color: white;
    background-color: white;
    height: 3px;
    position: absolute;
    bottom: -3px;
    border-radius: 5px;
  }

  h4 {
    font-family: $poppins;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  .cardcategories__parentDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .cardcategories__titleDiv {
    padding: 2.5vw 2vw;

    ::before {
      height: 3px;
      bottom: -3px;
      border-radius: 5px;
    }

    h4 {
      font-size: 1rem;
    }
  }
  .cardcategories__divOne {
    ::before {
      content: '';
      background: linear-gradient(
        0deg,
        rgba($primary, 0.9) 6%,
        rgba($primary, 0) 90%,
        rgba($primary, 0.5) 100%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      z-index: -1;

      transition: opacity 0.5s;
    }
  }

  .cardcategories__titleDiv {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(black, 0.8) 3%,
      rgba(black, 0) 90%,
      rgba(black, 0.5) 100%
    );
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 20px;
    z-index: 1;

    h4 {
      font-family: $poppins;
      font-size: 1.1rem;
      font-weight: 600;
      color: white;
      border-bottom: 3px solid white;
      border-radius: 3px;
      max-width: 0%;

      transition: 0.5s;
    }
  }
}

@media (hover: hover) and (min-width: 800px) {
  .cardcategories__parentDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    :hover {
      h4 {
        font-size: 1.3rem;
        max-width: 100%;
      }
      ::before {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .cardcategories__parentDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .cardcategories__divOne {
    ::before {
      content: '';
      background: linear-gradient(
        0deg,
        rgba($primary, 0.9) 6%,
        rgba($primary, 0) 90%,
        rgba($primary, 0.5) 100%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      z-index: -1;

      transition: opacity 0.5s;
    }
  }

  .cardcategories__titleDiv {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(black, 0.8) 3%,
      rgba(black, 0) 90%,
      rgba(black, 0.5) 100%
    );
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 20px;
    z-index: 1;

    h4 {
      font-family: $poppins;
      font-size: 1.3rem;
      font-weight: 600;
      color: white;
      border-bottom: 3px solid white;
      border-radius: 3px;
      max-width: 0%;

      transition: 0.5s;
    }
  }
}

@media (hover: hover) and (min-width: 1200px) {
  .cardcategories__parentDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    :hover {
      h4 {
        font-size: 1.5rem;
        max-width: 100%;
      }
      ::before {
        opacity: 1;
      }
    }
  }
}
