.horarios__horariosDiv {
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
  width: 100%;
}

.horarios__iconoHorariosDiv {
  display: flex;
  align-items: center;
  width: 7rem;
  background-color: $primary;
  padding: 1rem;
}

.horarios__iconoHorarios {
  width: 100%;
  font-size: 50px;
  color: white;
}

.horarios__detalleHorariosDiv {
  font-family: $poppins;
  width: 100%;
  text-align: start;
  padding: 1rem;

  h4 {

  }

  p{
    color: rgba(0, 0, 0, 0.8);
  }
}

.horarios__horaDetalleHorariosDiv {
  display: flex;
  justify-content: center;

  p {
    margin: 0 5px;
  }
}

.horarios__tarjetaDiv {
  display: flex;
}

@media screen and (min-width:800px) {
  .horarios__horariosDiv {
    min-height: 150px;
  }
}

@media screen and (min-width:1200px) {
  .horarios__horariosDiv {
    min-height: 200px;
  }
  .horarios__iconoHorarios {
    font-size: 80px;
  }
  .horarios__iconoHorariosDiv {
    width: 12rem;
    padding: 2rem;
  }
  .horarios__detalleHorariosDiv {
    padding: 1.5rem;
  
    h4 {
      font-size: 22px;
    }
  
    p{
      font-size: 20px;
    }
  }
  
}