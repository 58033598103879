.navbar__header {
  background-color: $primary;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__parentDiv {
  display: flex;
  flex: 1;
  align-items: center;
  height: 5rem;
  max-width: 1400px;
  margin: 0 1rem;

}

.navbar__menuIcon{
  display: none;
}

.navbar__childDiv {
  height: 100%;
  display: flex;
  z-index: 1;
  flex: 1;
}

.navbar__logoLink {
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar__logoLink img {
  align-items: center;
  height: 90%;
}

.navbar__menuList {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  list-style: none;
  position: absolute;
  flex: 1;
}

.navbar__childNavlink {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0rem 0.5rem;
  font-size: 1.2rem;
  font-family: $poppins;
  transition: .5s;

  &:hover{
    background-color: $secondary;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 2px;
  }
}

.navbar__childNavlink_active {
  margin: 0rem 0.5rem;
  font-size: 1.2rem;
  font-family: $poppins;
  text-decoration: none;
  transition: all 0.2s ease;
  background-color: $secondary;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

@media screen and (min-width:800px) {
  .navbar__menuList {
    position: relative;
  }
}

@media screen and (max-width:799px) {
  .navbar__childDiv{
    padding-left: 0.5rem;
    flex: 1;
  }

  .navbar__logoLink {
    width: 50%;
  }

  .navbar__menuIcon {
    display: flex;
    padding-right: 2rem;
    font-size: 1.6rem;
  }

  .fa {
    color: white;
  }

  .fas{
    color: white;
    cursor: pointer;
  }

  .navbar__menuList {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100% - 5rem);
    position: fixed;
    background-color: darken($primary, 4);
    right: 0;
    top: 5rem;
    z-index: 2;
    transition: all 0.5s ease-in-out;
  }

 

  .navbar__childNavlink {
    margin-bottom: 20px;
    font-size: 1.8rem;
    padding: 1rem 0;

    &:hover{
      margin-bottom: 20px;
      font-size: 1.8rem;
      padding: 1rem 0;
      background-color: transparent;
    }
  }

  .navbar__childNavlink_active{
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 20px;
    font-family: $poppins;
    text-decoration: none;
    color: white;
    text-align: center;
    width: 100%;
    padding: 1rem 0;
    background-color: $secondary;
    border-radius: none;
    transition: all 0.2s ease;
    transition-property:background-color;
  }

  .navbar__menuIcon .fa-times {
    transform: rotate(360deg);
    transition: all 0.2s ease-in-out;
  }

  .navbar__menuIcon .fa-bars {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
  }
  
  .navbar__menuList.close {
    right: -100%;
    transition: all 0.5s ease-in-out;
  }
}