.galeriaImagenes__titleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
    margin: 1rem 0;
    padding: 1rem;
   
    justify-self: center;
}

.galeriaImagenes__title {
    color: white;
    font-family: $poppins;
    font-size: 25px;

}

.galeriaImagenes__gridGaleria {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
}

.galeria1 {
    grid-column: span 2;
}

.galeria4 {
    grid-column: span 2;
}

.galeria8 {
    grid-column: span 3;
}

.galeriaImagenes__divGaleria {

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width:799px) {
    .galeriaImagenes__gridGaleria {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;
    }
    .galeria1 {
        grid-column: span 1;
    }
    
    .galeria4 {
        grid-column: span 1;
    }
    
    .galeria8 {
        grid-column: span 1;
    }
}