.footer__divParent {
  background-color: $primary;
  padding: 1rem;
}

.footer__firstDiv {
  display: flex;
  flex-direction: column;
}

.footer__contactDiv {
  padding: 1rem 0;
}

.footer__h4 {
  color: white;
  font-family: $poppins;
  font-weight: 500;
  font-size: 1.5rem;
  border-bottom: solid 2px $secondary;
}

.footer__aContact {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 6px 0;
}

.footer__contactIcon {
  color: white;
  font-size: 22px;
  padding-right: 9px;
}

.footer__firstDiv span {
  color: white;
  font-family: $poppins;
}

.footer__menuDiv {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.footer__linksMenu {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-family: $poppins;
  font-size: 1rem;
}

.footer__thirdtDiv {
  width: 100%;
}

.footer__iconsDiv {
  padding: 1rem 0;
}

.foooter__iconsDivH4 {
  color: white;
  font-family: $poppins;
  font-weight: 500;
  padding-bottom: 1rem;
}

.footer__icons {
  font-size: 2rem;
  color: white;
  padding-right: 1rem;
}

.footer__copyDiv {
  background-color: $dark-grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__copyDiv h5 {
  font-family: $poppins;
  padding: 0.3rem;
  color: white;
  font-size: 1rem;
}

@media screen and (min-width: 800px) {
  .footer__divParent {
    padding: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .footer__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary;
  }

  .footer__divParent {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    background-color: $primary;
    width: 100%;
    max-width: 1400px;
  }

  .footer__linksMenu {
    font-size: 18px;
  }

  .footer__firstDiv span {
    font-size: 18px;
  }

  .footer__icons {
    font-size: 40px;
  }

  .footer__icons:hover {
    color: $secondary;
    transition: 0.4s;
  }

  .foooter__iconsDivH4 {
    font-size: 18px;
    padding-bottom: 1rem;
  }

  .footer__icons {
    padding-right: 20px;
  }

  .footer__linksMenu:hover {
    color: $secondary;
    transition: 0.4s;
  }

  .footer__copyDiv {
    width: 100%;
  }
}

.footer-copyright {
  color: #ffff;
  font-family: $poppins;
}

.footer-section {
  background: #121729;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 64px;
  padding-top: 32px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 16px; /* Equivale a text-sm en Tailwind */
  gap: 12px;
  width: 100%;
  max-width: 1400px; /* Equivale a max-w-6xl en Tailwind */
  padding: 0 32px; /* Para diferentes tamaños de pantalla */
}

.footer_imggg {
  width: 130px;
}

.footer-developed-by {
  color: #ffff;
  font-family: $poppins;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    font-size: 1rem; /* Equivale a text-base en Tailwind */
    gap: 20px;
    margin: 0 40px;
  }
}

.footer-copyright {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footer-developed-by {
  display: flex;
  align-items: center;
  gap: 8px;
}

.grvity-link {
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.grvity-link:hover {
  opacity: 0.7;
}

.grvity-logo {
  height: 20px;
  width: 50px; /* Equivale a w-32 en Tailwind */
  color: white;
  transition: color 0.3s ease; /* Equivale a transition-colors en Tailwind */
}

.grvity-link:hover .grvity-logo {
  color: #d1fa2d;
}
