
.categories__parentDiv {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}

.categories__gridDiv{
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr)
}

@media screen and (min-width:800px) {
  
.categories__parentDiv {
  padding: 0 2rem;
}
  
  .categories__gridDiv{
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr)
  }
}

@media screen and (min-width:1200px) {
  
.categories__parentDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
  
  .categories__gridDiv{
    gap: 2rem 3rem;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1400px;
    margin: 0 5rem;
  }
}