.envios__enviosDiv {
  display: flex;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  width: 100%;
}

.envios__iconoEnviosDiv {
  display: flex;
  align-items: center;
  width: 7rem;
  background-color: $primary;
  padding: 1rem;
}

.envios__iconoEnvios {
  width: 100%;
  font-size: 50px;
  color: white;
}

.envios__detalleEnviosDiv {
  font-family: $poppins;
  width: 100%;
  text-align: start;
  padding: 1rem;

  p {
    color: rgba(0, 0, 0, 0.8);
  }
}

.envios__horaDetalleEnviosDiv {
  display: flex;
  justify-content: center;

  p {
    margin: 0 5px;
  }
}

.envios__tarjetaDiv {
  display: flex;
}

@media screen and (min-width: 800px) {
  .envios__enviosDiv {
    min-height: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .envios__enviosDiv {
    min-height: 200px;
  }

  .envios__iconoEnviosDiv {
    width: 12rem;
    padding: 2rem;
  }

  .envios__iconoEnvios {
    font-size: 80px;
  }

  .envios__detalleEnviosDiv {
    padding: 1.5rem;

    h4 {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }
  }

  .nosotros__text {
    h4 {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }
  }
}
